@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

*{
  @apply transition-colors duration-700;
  /* scroll-behavior: smooth; */
}
html {
  scroll-behavior: smooth;
}

/* @import url('./assets/fonts/berlinSansFB.css'); */
@import url('./assets/fonts/berlinSansFBDemi.css');

.bgSize{
  background-size: 100% 100%;
}

.cardHover {
  transition: all 0.3s ease-in-out;
}

.cardHover:hover {
  filter: hue-rotate(40deg); /* Change this value to adjust the color change */
  transform: scale(1.1); /* Scale the image slightly on hover */
}
.test{
  background-size:  100% 50%;
}